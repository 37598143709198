/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Chevron from 'img/chevron.inline.svg'
import dashes from 'img/dashes.svg'
import Check from 'img/check.inline.svg'
import Phone from 'img/phone.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: ${(props) => props.theme.color.light};
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  height: 150px;
  z-index: 10;

  @media screen and (max-width: 991px) {
    top: 0;
    height: 100px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
`

const HeaderTopper = styled.div`
  background-color: ${(props) => props.theme.color.grey};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 50px;
  z-index: 10;

  & svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 1199px) {
    font-size: 16px;
  }

  @media screen and (max-width: 991px) {
    z-index: 100;
    bottom: 0;
    top: unset;
    height: fit-content;
  }
`

const CheckWrapper = styled.div`
  @media screen and (max-width: 1199px) {
    display: none;
  }
`

const OtherWrapper = styled.div`
  @media screen and (max-width: 1199px) {
    width: 100%;
  }

  & a {
    font-size: 14px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  & > svg {
    height: 63px;
    width: 172px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 100%;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  height: 6px;
  width: 60%;
  border-radius: 2px;
  margin: 6px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const StyledButtonPrimary = styled(ButtonPrimary)`
  height: 100%;

  & > a {
    padding: 10px 30px;
  }
`

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const { fields, vacancyCount, opaqueBackground } =
    useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
      query headerQuery {
        fields: wpComponent(databaseId: { eq: 97 }) {
          header {
            menuHeader {
              link {
                title
                url
              }
              submenu {
                link {
                  title
                  url
                }
              }
            }
            extrainfo {
              lefttext
              phones {
                phone {
                  title
                  url
                }
              }
              button {
                title
                url
              }
            }
          }
        }

        vacancyCount: allWpVacancy {
          totalCount
        }

        opaqueBackground: file(name: { eq: "opaque14" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 500, placeholder: NONE)
          }
        }
      }
    `)

  return (
    <>
      <HeaderTopper className="d-lg-block d-none">
        <div className="container h-100">
          <div className="h-100 d-flex justify-content-between align-items-center">
            <CheckWrapper>
              <Check />
              {fields?.header?.extrainfo?.lefttext}
            </CheckWrapper>
            <OtherWrapper className="d-flex h-100 align-items-center justify-content-between">
              {fields?.header?.extrainfo?.phones?.map((p, index) => (
                <a href={p?.phone?.url} key={index} className="d-flex mx-3">
                  <Phone />
                  {p?.phone?.title}
                </a>
              ))}
              <StyledButtonPrimary
                to={fields?.header?.extrainfo?.button?.url || ''}
              >
                {fields?.header?.extrainfo?.button?.title}
              </StyledButtonPrimary>
            </OtherWrapper>
          </div>
        </div>
      </HeaderTopper>
      <StyledHeader isSticky={isSticky}>
        <Container className="container">
          <Brand to="/">
            <Logo />
          </Brand>

          <MenuMobile
            fields={fields}
            setIsScrollable={setIsScrollable}
            isSticky={isSticky}
            vacancyCount={vacancyCount}
            opaqueBackground={opaqueBackground}
          />
          <MenuDesktop
            vacancyCount={vacancyCount}
            fields={fields}
            isSticky={isSticky}
          />
        </Container>
      </StyledHeader>
    </>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: left;
    width: 80%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        color: ${({ theme }) => theme.color.dark};
        font-size: 24px;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.dark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 6px;
    border-radius: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

interface MenuMobileProps {
  fields: any
  isSticky?: boolean
  setIsScrollable: Function
  vacancyCount: any
  opaqueBackground: any
}

const StyledBackground = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 320px;
  width: 500px;

  @media screen and (max-width: 501px) {
    width: 100%;

    & img {
      object-fit: contain !important;
    }
  }
`

const MenuMobile: React.FC<MenuMobileProps> = ({
  vacancyCount,
  fields,
  setIsScrollable,
  opaqueBackground,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <StyledBackground image={{ localFile: opaqueBackground }} alt="" />
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu vacancyCount={vacancyCount} fields={fields} />
          </ul>
          <HeaderTopper>
            <div className="container h-100 py-3">
              <div className="h-100 d-flex flex-wrap justify-content-center align-items-center">
                <div className="pb-3">
                  <Check />
                  {fields?.header?.extrainfo?.lefttext}
                </div>
                <div className="d-flex flex-wrap h-100 justify-content-center align-items-center">
                  {fields?.header?.extrainfo?.phones?.map(
                    (p: any, index: number) => (
                      <a
                        href={p?.phone?.url}
                        key={index}
                        className="d-flex py-2"
                      >
                        <Phone />
                        {p?.phone?.title}
                      </a>
                    )
                  )}
                  <div className="w-100 pt-3 d-flex justify-content-center">
                    <StyledButtonPrimary
                      to={fields?.header?.extrainfo?.button?.url || ''}
                    >
                      {fields?.header?.extrainfo?.button?.title}
                    </StyledButtonPrimary>
                  </div>
                </div>
              </div>
            </div>
          </HeaderTopper>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
`

interface MenuDesktopProps {
  fields: any
  isSticky?: boolean
  vacancyCount: any
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ vacancyCount, fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu vacancyCount={vacancyCount} fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  margin: 0 40px 0 0;
  position: relative;

  & svg {
    margin-left: 10px;
  }

  @media (min-width: 992px) {
    &:last-child {
      display: none;
    }
  }

  & > a {
    color: ${(props) => props.theme.color.dark};
    font-size: 16px;
    line-height: 20px;

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-image: url(${dashes});
        background-repeat: no-repeat;
        background-size: contain;
        width: 21px;
        height: 19px;
        left: -25px;
        top: 0px;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1199px) {
    margin: 0 15px 0 0;

    & > a {
      font-size: 14px;

      &[aria-current] {
        &::before {
          left: -20px;
          top: -2px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin: 0;
    min-height: 56px;
    display: flex;
    align-items: center;

    & > a {
      line-height: 20px !important;

      &[aria-current] {
        &::before {
          top: 10px;
        }
      }
    }
  }
`

interface MenuProps {
  fields: any
  vacancyCount: any
}

const Count = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.primary};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.light};
  line-height: 14px;
  font-size: 14px;
  top: -10px;
  right: -20px;

  @media screen and (max-width: 991px) {
    right: unset;
    left: 105px;
    top: 0px;
  }
`

const ParentNavLink = styled(motion.div)`
  > a[aria-current] {
    font-weight: bold;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: url(${dashes});
      background-repeat: no-repeat;
      background-size: contain;
      width: 21px;
      height: 19px;
      left: -25px;
      top: 0px;
    }
  }

  @media (max-width: 1199px) {
    & > a {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    > a[aria-current] {
      &::before {
        top: 18px;
      }
    }
  }
`

const Submenu = styled(motion.div)<{ type: string }>`
  position: absolute;
  overflow: hidden;
  padding: 10px 0 0 0;

  & > div {
    background-color: ${({ theme }) => theme.color.light};
    /* box-shadow: 0 0 3px rgba(0,0,0,0.3); */
    padding: 20px 20px 20px 30px;
    min-width: 100%;
    position: relative;

    & a {
      padding: 5px 0;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    & a[aria-current] {
      font-weight: bold;
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 10px;
      ${({ type, theme }) =>
        type === 'primary' &&
        css`
          background-color: ${theme.color.primary};
        `}
      ${({ type, theme }) =>
        type === 'secondary' &&
        css`
          background-color: ${theme.color.secondary};
        `}
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    padding: 0;

    & > div {
      padding: 10px 10px 10px 20px;

      & a {
        padding: 2px;
        font-size: 18px !important;
        line-height: 18px !important;
      }

      &:before {
        /* content: none; */
      }
    }
  }
`

const Menu: React.FC<MenuProps> = ({ vacancyCount, fields }) => (
  <>
    {fields.header.menuHeader.map(
      ({ link: { url, title }, submenu }: any, index: number) => {
        const [open, setOpen] = React.useState(false)

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index}>
            {submenu ? (
              <ParentNavLink
                onTap={() => setOpen(true)}
                onHoverStart={() => setOpen(true)}
                onHoverEnd={() => setOpen(false)}
              >
                <NavLink to={url}>
                  {title}
                  <Chevron />
                </NavLink>
                <AnimatePresence>
                  {open && (
                    <Submenu
                      onTap={(e: any) => e.stopPropagation()}
                      type={
                        title === 'Zakelijke beveiliging'
                          ? 'primary'
                          : 'secondary'
                      }
                      initial={{ height: 0 }}
                      animate={{ height: 'fit-content' }}
                      exit={{ height: 0 }}
                    >
                      <div className="d-flex flex-column">
                        {submenu.map((m: any, sindex: number) => (
                          <NavLink key={sindex} to={m.link.url || ''}>
                            {m.link.title}
                          </NavLink>
                        ))}
                      </div>
                    </Submenu>
                  )}
                </AnimatePresence>
              </ParentNavLink>
            ) : (
              <NavLink to={url}>
                {title}
                {title === 'Vacatures' && (
                  <Count>{vacancyCount.totalCount}</Count>
                )}
              </NavLink>
            )}
          </Item>
        )
      }
    )}
  </>
)

export default Header
